import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mail" }
const _hoisted_2 = { "aria-label": "breadcrumb" }
const _hoisted_3 = { class: "breadcrumb" }
const _hoisted_4 = { class: "breadcrumb-item" }
const _hoisted_5 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "row mb-3" }
const _hoisted_8 = { class: "col-md" }
const _hoisted_9 = {
  for: "name",
  class: "form-label"
}
const _hoisted_10 = { class: "col-md" }
const _hoisted_11 = {
  for: "lastname",
  class: "form-label"
}
const _hoisted_12 = { class: "row mb-3" }
const _hoisted_13 = { class: "col-md" }
const _hoisted_14 = {
  for: "index",
  class: "form-label"
}
const _hoisted_15 = {
  id: "index-help",
  class: "form-text"
}
const _hoisted_16 = { class: "col-md" }
const _hoisted_17 = {
  for: "jmbg",
  class: "form-label"
}
const _hoisted_18 = { class: "row mb-3" }
const _hoisted_19 = { class: "col-md" }
const _hoisted_20 = {
  for: "private",
  class: "form-label"
}
const _hoisted_21 = {
  id: "private-help",
  class: "form-text"
}
const _hoisted_22 = { class: "col-md" }
const _hoisted_23 = {
  for: "singimail",
  class: "form-label"
}
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = { class: "mb-3" }
const _hoisted_26 = {
  for: "message",
  class: "form-label"
}
const _hoisted_27 = { class: "mb-3 form-check" }
const _hoisted_28 = ["innerHTML"]

import {useReCaptcha} from 'vue-recaptcha-v3'
import {MainService} from "@/service/main.service";
import {ref} from "vue";
import {AnimationHelper} from "@/utils/animation.helper";
import {TranslationModel} from "@/models/translation.model";
import PageFooter from './PageFooter.vue';

export interface MailProps {
  lang: TranslationModel
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Mail',
  props: {
    lang: {}
  },
  setup(__props: any) {



const name = ref()
const lastname = ref()
const index = ref()
const jmbg = ref()
const privateMail = ref()
const studentMail = ref()
const message = ref()
const agree = ref()

//@ts-ignore
const {executeRecaptcha, recaptchaLoaded} = useReCaptcha()

async function sendForm() {
  const loading = AnimationHelper.showLoading(__props.lang.processing)
  loading.showLoading();

  await recaptchaLoaded()
  const token = await executeRecaptcha('submit')

  if (name.value == undefined || name.value == '') {
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.name_err)
    return
  }

  if (lastname.value == undefined || lastname.value == '') {
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.lastname_err)
    return
  }

  if (index.value == undefined || index.value == '') {
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.index_err)
    return
  }

  if (jmbg.value == undefined || jmbg.value == '') {
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.jmbg_err)
    return
  }

  if (privateMail.value == undefined || privateMail.value == '') {
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.priv_email_err)
    return
  }

  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!privateMail.value.match(regex)) {
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.priv_email_malformed)
    return
  }

  if (studentMail.value != undefined && !validateSingiMail(studentMail.value)) {
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.stud_email_malformed)
    return
  }

  if (agree.value == undefined || agree.value == false) {
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.agree_err)
    return
  }

  MainService.submitSingimail({
    name: name.value,
    lastname: lastname.value,
    index: index.value,
    jmbg: jmbg.value,
    private: privateMail.value,
    student: studentMail.value,
    message: message.value,
    token: token,
    lang: __props.lang.lang_name
  }).then(rsp => {
    loading.close()
    AnimationHelper.alert(__props.lang.success_title, __props.lang.success_msg)
  }).catch(e => {
    console.log(e)
    loading.close()
    AnimationHelper.alertError(__props.lang.error_title, __props.lang.error_msg)
  })
}

function validateSingiMail(email: string) {
  return /^\"?[\w-_\.]*\"?@singimail\.rs$/.test(email);
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ol", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: _ctx.lang.base_url
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.lang.home), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", _hoisted_5, _toDisplayString(_ctx.lang.mail_breadcrumb), 1)
      ])
    ]),
    _createElementVNode("h4", null, _toDisplayString(_ctx.lang.mail_title), 1),
    _createElementVNode("p", {
      innerHTML: _ctx.lang.mail_desc
    }, null, 8, _hoisted_6),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, [
          _cache[8] || (_cache[8] = _createElementVNode("i", { class: "us-red" }, "*", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.lang.name) + ":", 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "name",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event))
        }, null, 512), [
          [_vModelText, name.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, [
          _cache[9] || (_cache[9] = _createElementVNode("i", { class: "us-red" }, "*", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.lang.lastname) + ":", 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "lastname",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((lastname).value = $event))
        }, null, 512), [
          [_vModelText, lastname.value]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("label", _hoisted_14, [
          _cache[10] || (_cache[10] = _createElementVNode("i", { class: "us-red" }, "*", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.lang.index) + ":", 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "index",
          "aria-describedby": "index-help",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((index).value = $event))
        }, null, 512), [
          [_vModelText, index.value]
        ]),
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.lang.index_hint), 1)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", _hoisted_17, [
          _cache[11] || (_cache[11] = _createElementVNode("i", { class: "us-red" }, "*", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.lang.jmbg) + ":", 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "jmbg",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((jmbg).value = $event))
        }, null, 512), [
          [_vModelText, jmbg.value]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("label", _hoisted_20, [
          _cache[12] || (_cache[12] = _createElementVNode("i", { class: "us-red" }, "*", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.lang.private_mail) + ":", 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "private",
          "aria-describedby": "private-help",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((privateMail).value = $event))
        }, null, 512), [
          [_vModelText, privateMail.value]
        ]),
        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.lang.private_mail_hint), 1)
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.lang.student_mail) + ":", 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: "singimail",
          placeholder: _ctx.lang.student_mail_example,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((studentMail).value = $event))
        }, null, 8, _hoisted_24), [
          [_vModelText, studentMail.value]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.lang.message) + ":", 1),
      _withDirectives(_createElementVNode("textarea", {
        type: "text",
        class: "form-control",
        id: "message",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((message).value = $event))
      }, null, 512), [
        [_vModelText, message.value]
      ])
    ]),
    _createElementVNode("div", _hoisted_27, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        class: "form-check-input",
        id: "check",
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((agree).value = $event))
      }, null, 512), [
        [_vModelCheckbox, agree.value]
      ]),
      _createElementVNode("label", {
        class: "form-check-label",
        for: "check",
        innerHTML: _ctx.lang.agree
      }, null, 8, _hoisted_28)
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-red mb-3",
      onClick: sendForm
    }, _toDisplayString(_ctx.lang.send), 1),
    _createVNode(PageFooter, {
      text: _ctx.lang.footer
    }, null, 8, ["text"])
  ]))
}
}

})