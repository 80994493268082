<template>
  <footer>
    <p class="text-center">&copy; {{ year }} {{ text }}</p>
  </footer>
</template>

<script setup lang="ts">
export interface FooterProps {
  text: string
}

const {text} = defineProps<FooterProps>()
const year = new Date().getFullYear()
</script>