import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export interface FooterProps {
  text: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PageFooter',
  props: {
    text: {}
  },
  setup(__props: any) {


const year = new Date().getFullYear()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("p", _hoisted_1, "© " + _toDisplayString(_unref(year)) + " " + _toDisplayString(_ctx.text), 1)
  ]))
}
}

})