import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.png'
import _imports_1 from '@/assets/img/office.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex gap-1 flex-row-reverse" }

import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter()

function goHome() {
  router.push({
    path: '/home'
  })
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "btn fi fi-rs lang",
        to: "/home"
      }),
      _createVNode(_component_router_link, {
        class: "btn fi fi-gb lang",
        to: "/en/home"
      })
    ]),
    _createElementVNode("div", {
      class: "image-container mb-0",
      onClick: goHome
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Singidunum University",
        class: "img-fluid cursor-pointer",
        width: "350"
      }, null, -1),
      _createElementVNode("img", {
        src: _imports_1,
        alt: "Office365 Education",
        class: "img-fluid cursor-pointer",
        width: "560"
      }, null, -1)
    ])),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    }))
  ]))
}
}

})