import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/outlook.png'
import _imports_1 from '@/assets/img/teams.png'


const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "row p-5" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "d-flex flex-wrap justify-content-center align-items-center gap-3 mb-3" }
const _hoisted_5 = {
  href: "https://go.microsoft.com/fwlink/p/?linkid=2125442",
  class: "btn btn-outlook btn-home text-center"
}
const _hoisted_6 = {
  href: "https://go.microsoft.com/fwlink/p/?linkid=873020",
  class: "btn btn-teams btn-home text-center"
}
const _hoisted_7 = { class: "d-flex flex-wrap justify-content-center align-items-center gap-3 mb-3" }
const _hoisted_8 = {
  key: 0,
  class: "row"
}
const _hoisted_9 = { class: "col-12 col-md-6" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "col-12 col-md-6" }
const _hoisted_12 = { class: "input-group mx-auto mb-3 w-80" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = {
  class: "accordion accordion-flush",
  id: "questions"
}
const _hoisted_15 = { class: "accordion-item" }
const _hoisted_16 = ["id"]
const _hoisted_17 = ["data-bs-target", "aria-controls"]
const _hoisted_18 = ["id", "aria-labelledby"]
const _hoisted_19 = ["innerHTML"]

import Loading from "@/components/Loading.vue";
import { onMounted, ref } from "vue";
import { TranslationModel } from "@/models/translation.model";
import { QuestionModel } from "@/models/question.model";
import linkifyHtml from "linkify-html";
import PageFooter from "./PageFooter.vue";

export interface HomeProps {
  lang: TranslationModel,
  callback: Function
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  props: {
    lang: {},
    callback: { type: Function }
  },
  setup(__props: any) {




const questions = ref<QuestionModel[]>()
const search = ref<string>('')

function retrieveData() {
  __props.callback(search.value).then((rsp: { data: QuestionModel[] | undefined; }) => {
    questions.value = rsp.data
  })
}

onMounted(() => retrieveData())

function parseQuestionBody(question: QuestionModel) {
  return linkifyHtml(question.answer, {
    attributes: {
      target: '_blank'
    }
  });
}


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.lang.home_title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            src: _imports_0,
            alt: "Outlook Icon",
            class: "mb-2 img-link"
          }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.lang.home_outlook), 1)
        ]),
        _createElementVNode("a", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_1,
            alt: "Teams Icon",
            class: "mb-2 img-link"
          }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.lang.home_teams), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_RouterLink, {
          to: _ctx.lang.home_support_url,
          class: "btn btn-red text-center btn-home",
          innerHTML: _ctx.lang.home_support
        }, null, 8, ["to", "innerHTML"])
      ])
    ]),
    (questions.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lang.home_desc, (text) => {
              return (_openBlock(), _createElementBlock("p", { innerHTML: text }, null, 8, _hoisted_10))
            }), 256))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "input-group-text" }, [
                _createElementVNode("i", { class: "fa-solid fa-magnifying-glass" })
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                placeholder: _ctx.lang.search,
                onKeyup: retrieveData
              }, null, 40, _hoisted_13), [
                [_vModelText, search.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questions.value, (question) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("h2", {
                    class: "accordion-header",
                    id: 'qh-' + question.questionId
                  }, [
                    _createElementVNode("button", {
                      class: "accordion-button collapsed",
                      type: "button",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": '#qb-' + question.questionId,
                      "aria-expanded": "false",
                      "aria-controls": '#qb-' + question.questionId
                    }, [
                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa-solid fa-graduation-cap icon" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(question.text), 1)
                    ], 8, _hoisted_17)
                  ], 8, _hoisted_16),
                  _createElementVNode("div", {
                    id: 'qb-' + question.questionId,
                    class: "accordion-collapse collapse",
                    "aria-labelledby": 'qh-' + question.questionId,
                    "data-bs-parent": "#questions"
                  }, [
                    _createElementVNode("div", {
                      class: "accordion-body",
                      innerHTML: parseQuestionBody(question)
                    }, null, 8, _hoisted_19)
                  ], 8, _hoisted_18)
                ]))
              }), 256))
            ])
          ]),
          _createVNode(PageFooter, {
            text: _ctx.lang.footer
          }, null, 8, ["text"])
        ]))
      : (_openBlock(), _createBlock(Loading, {
          key: 1,
          message: _ctx.lang.loading
        }, null, 8, ["message"]))
  ]))
}
}

})