import Swal from 'sweetalert2';

const sweetAlertsBootstrap = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-1',
        cancelButton: 'btn btn-outline-primary m-1'
    },
    buttonsStyling: false
})

export class AnimationHelper {

    public static alert(title: string, desc: string): void {
        sweetAlertsBootstrap.fire({
            title: title,
            text: desc,
            icon: 'success'
        })
    }

    public static alertError(title: string, desc: string): void {
        sweetAlertsBootstrap.fire({
            title: title,
            text: desc,
            icon: 'error'
        })
    }

    public static showLoading(msg: string) {
        sweetAlertsBootstrap.fire({
            title: msg
        })
        return sweetAlertsBootstrap
    }
}