import {TranslationModel} from "@/models/translation.model";

export const sr: TranslationModel = {
    loading: 'Molimo sačekajte',
    lang_name: 'sr',
    footer: 'Unverzitet Singidunum',
    home: 'Početna',
    home_desc: [
      'Kompanija Microsoft i Univerzitet Singidunum uspostavili su značajnu saradnju s ciljem pružanja pristupa digitalnim resursima studentima ovog univerziteta. Kroz ovu saradnju, svi studenti sa singimail.rs adresama imaju priliku da besplatno koriste brojne Microsoftove proizvode, uključujući Teams, Office 365 i Outlook.',
      'Ova inicijativa deo je Microsoft Education programa, koji je osmišljen da unapredi digitalnu pismenost i omogući studentima i obrazovnim ustanovama širom sveta pristup alatima potrebnim za učenje i saradnju. Microsoft Education program obuhvata niz alata i resursa prilagođenih za obrazovanje, pružajući studentima savremene mogućnosti za usvajanje znanja i unapređenje veština potrebnih na tržištu rada.',
      'Uz Microsoft Teams, studenti Univerziteta Singidunum mogu efikasno sarađivati na projektima, prisustvovati online predavanjima i razmenjivati ideje sa profesorima i kolegama. Paket Office 365 nudi pristup poznatim alatima kao što su Word, Excel i PowerPoint, koji su od ključne važnosti za svakodnevne studentske zadatke i profesionalni razvoj. Outlook olakšava organizaciju vremena i komunikaciju, dok integracija svih ovih alata doprinosi produktivnosti i kvalitetu obrazovanja.',
      'Ova saradnja predstavlja još jedan korak ka digitalizaciji obrazovanja i omogućava studentima Univerziteta Singidunum da iskoriste vrhunsku tehnologiju u svom obrazovanju i budućim karijerama.'
    ],
    home_title: 'Aktivacija Vašeg studentskog Office365/Teams naloga',
    home_teams: 'Pristup Microsoft Teams platformi',
    home_outlook: 'Pristup studentskom mejlu (Outlook aplikacija)',
    home_support: '<i class="fa-solid fa-arrow-up-right-from-square"></i> Formular za resetovanje lozinke',
    home_support_url: '/support/mail',
    base_url: '/',
    mail_title: 'Formular za izmenu lozinke studentskog mejla',
    mail_desc: `Studentska mejl adresa je jedini zvaničan način komunikacije na Univerzitetu Singidunum<br>
      Nalozi se dobiju po upisu i prate šemu ime.prezime.godinaupisa@singimail.rs gde godina upisa pretstavlja poslednje
      dve cifre
      godine upisa (npr. pera.peric.23@singimail.rs)<br>
      Inicijalna lozinka svake mejl adrese jeste matični broj ili broj pasoša ukoliko je student strani državljanin.<br>
      Ukoliko ste zaboravili lozinku popunite formular ispod.`,
    mail_breadcrumb: 'Izmena lozinke Singimail',
    name: 'Ime',
    lastname: 'Prezime',
    index: 'Broj Indeksa',
    jmbg: "JMBG / Broj Pasoša",
    private_mail: 'Privatna Email adresa',
    student_mail: 'SingiMail adresa',
    student_mail_example: 'ime.prezime.23@singimail.rs',
    message: 'Poruka',
    agree: `Potvrđujem da sam uneo <span class="fw-bold">moje lične podatke</span> a ne podatke drugog studenta`,
    send: 'Pošalji zahtev',
    name_err: 'Morate uneti Vaše ime',
    lastname_err: 'Morate uneti Vaše prezime',
    index_err: 'Morate uneti Vaš broj indeksa',
    jmbg_err: 'Morate uneti Vaš matični broj, odnosno broj pasoša',
    priv_email_err: 'Morate uneti Vašu privatnu mejl adresu kako bi dobili nove parametre',
    stud_email_err: 'Morate uneti Vašu singimejl studentsku adresu',
    priv_email_malformed: 'Privatna adresa je u pogrešnom formatu',
    stud_email_malformed: 'Singimail adresa je u pogrešnom formatu',
    agree_err: 'Morate potvrditi da ste uneli vaše lične podatke',
    success_msg: 'Vaš zahtev je uspešno poslat. Molimo Vas da budete strpljivi dok se vaš zahtev ne obradi',
    error_msg: 'Na žalost došlo je do greške. Molimo Vas da pokušate ponovo kasnije',
    private_mail_hint: 'Na ovu mejl adresu ćete dobiti novu parametre vaše mejl adrese',
    index_hint: 'Indeks mora biti u formatu 2019123456 (gde 2019 predstavlja godinu upisa)',
    success_title: 'Uspešno izvršeno!',
    error_title: 'Greška!',
    processing: 'Zahtev se izvršava...',
    search: 'Pretraga'
}