import axios from "axios";

const client = axios.create({
    baseURL: 'https://hub.singidunum.ac.rs/api',
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-token': '55a871c7-6a5f-4076-a7b2-a9f8e91ecffd'
    }
});

export class MainService {
    public static async submitSingimail(data: any) {
        return await client.request({
            method: 'post',
            url: '/singimail/new',
            data: data
        })
    }
}