import axios from "axios";

const client = axios.create({
    baseURL: 'https://rasa.singidunum.ac.rs/api/question',
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-token': '9c67ca1d-37f3-47c9-9c3a-ffbe47622e93'
    }
});

export class QuestionService {
    public static async getQuestions(search: string) {
        return await client.get('/category/singimail?search=' + search)
    }

    public static async getQuestionsEng(search: string) {
        return await client.get('/category/en-singimail?search=' + search)
    }
}