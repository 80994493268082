import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/loading.gif'


const _hoisted_1 = { class: "text-center mx-auto" }
const _hoisted_2 = { class: "fw-bold" }

export interface LoadingProps {
  message: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Loading',
  props: {
    message: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: "loading"
    }, null, -1)),
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.message), 1)
  ]))
}
}

})