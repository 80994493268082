import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {sr} from "@/languages/sr";
import {en} from "@/languages/en"
import Home from "@/components/Home.vue";
import Mail from "@/components/Mail.vue";
import {QuestionService} from "@/service/question.service";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        props: {
            lang: sr,
            callback: QuestionService.getQuestions
        }
    },
    {
        path: '/support/mail',
        name: 'mail',
        component: Mail,
        props: {
            lang: sr
        }
    },
    {
        path: '/en',
        redirect: '/en/home'
    },
    {
        path: '/en/home',
        name: 'home-en',
        component: Home,
        props: {
            lang: en,
            callback: QuestionService.getQuestionsEng
        }
    },
    {
        path: '/en/support/mail',
        name: 'en-mail',
        component: Mail,
        props: {
            lang: en
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/home'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
