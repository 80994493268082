<template>
  <div class="home">
    <div class="row p-5">
      <h3 class="text-center">{{ lang.home_title }}</h3>
      <div class="d-flex flex-wrap justify-content-center align-items-center gap-3 mb-3">
        <a href="https://go.microsoft.com/fwlink/p/?linkid=2125442" class="btn btn-outlook btn-home text-center">
          <img src="@/assets/img/outlook.png" alt="Outlook Icon" class="mb-2 img-link" />
          {{ lang.home_outlook }}
        </a>
        <a href="https://go.microsoft.com/fwlink/p/?linkid=873020" class="btn btn-teams btn-home text-center">
          <img src="@/assets/img/teams.png" alt="Teams Icon" class="mb-2 img-link" />
          {{ lang.home_teams }}
        </a>
      </div>
      <div class="d-flex flex-wrap justify-content-center align-items-center gap-3 mb-3">
        <RouterLink :to="lang.home_support_url" class="btn btn-red text-center btn-home" v-html="lang.home_support">
        </RouterLink>
      </div>
    </div>
    <div v-if="questions" class="row">
      <div class="col-12 col-md-6">
        <p v-for="text of lang.home_desc" v-html="text"></p>
      </div>
      <div class="col-12 col-md-6">
        <div class="input-group mx-auto mb-3 w-80">
          <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>
          <input type="text" class="form-control" v-model="search" :placeholder="lang.search" @keyup="retrieveData">
        </div>
        <div class="accordion accordion-flush" id="questions">
          <div v-for="question in questions" class="accordion-item">
            <h2 class="accordion-header" :id="'qh-' + question.questionId">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                :data-bs-target="'#qb-' + question.questionId" aria-expanded="false"
                :aria-controls="'#qb-' + question.questionId">
                <i class="fa-solid fa-graduation-cap icon"></i> {{ question.text }}
              </button>
            </h2>
            <div :id="'qb-' + question.questionId" class="accordion-collapse collapse"
              :aria-labelledby="'qh-' + question.questionId" data-bs-parent="#questions">
              <div class="accordion-body" v-html="parseQuestionBody(question)"></div>
            </div>
          </div>
        </div>
      </div>
      <PageFooter :text="lang.footer" />
    </div>
    <Loading :message="lang.loading" v-else />
  </div>
</template>

<script setup lang="ts">

import Loading from "@/components/Loading.vue";
import { onMounted, ref } from "vue";
import { TranslationModel } from "@/models/translation.model";
import { QuestionModel } from "@/models/question.model";
import linkifyHtml from "linkify-html";
import PageFooter from "./PageFooter.vue";

export interface HomeProps {
  lang: TranslationModel,
  callback: Function
}

const { lang, callback } = defineProps<HomeProps>()

const questions = ref<QuestionModel[]>()
const search = ref<string>('')

function retrieveData() {
  callback(search.value).then((rsp: { data: QuestionModel[] | undefined; }) => {
    questions.value = rsp.data
  })
}

onMounted(() => retrieveData())

function parseQuestionBody(question: QuestionModel) {
  return linkifyHtml(question.answer, {
    attributes: {
      target: '_blank'
    }
  });
}

</script>