import {TranslationModel} from "@/models/translation.model";

export const en: TranslationModel = {
    loading: 'Please wait',
    lang_name: 'en',
    footer: 'Singidunum University',
    home: 'Home',
    home_desc: [
        'Microsoft and Singidunum University have established a significant partnership to provide students with access to digital resources. Through this collaboration, all students with singimail.rs email addresses can use a wide range of Microsoft products for free, including Teams, Office 365, and Outlook.',
        'This initiative is part of the Microsoft Education program, designed to enhance digital literacy and provide students and educational institutions worldwide with access to the tools needed for learning and collaboration. The Microsoft Education program includes a variety of tools and resources tailored for education, offering students modern opportunities to acquire knowledge and develop skills essential in today’s job market.',
        'With Microsoft Teams, Singidunum University students can efficiently collaborate on projects, attend online lectures, and exchange ideas with professors and peers. The Office 365 suite offers access to essential tools like Word, Excel, and PowerPoint, which are vital for daily academic tasks and professional development. Outlook simplifies time management and communication, while the integration of these tools enhances productivity and the quality of education.',
        'This partnership represents another step towards the digitalization of education, enabling Singidunum University students to leverage cutting-edge technology in their studies and future careers.'
    ],
    home_title: 'Activate your student Office365/Teams account',
    home_teams: 'Microsoft Teams access',
    home_outlook: 'Student email access (Microsoft Outlook)',
    home_support: '<i class="fa-solid fa-arrow-up-right-from-square"></i> Password reset request form',
    home_support_url: '/en/support/mail',
    base_url: '/en',
    mail_title: 'Singimail Reset Form',
    mail_desc: `Student email address is the only valid way of communication on the Singidunum University`,
    mail_breadcrumb: 'Singimail Reset Form',
    name: 'First name',
    lastname: 'Last name',
    index: 'Index number',
    index_hint: 'Index number has to be in the following format: 2023123456 (where 2023 is the enrolment year)',
    jmbg: "UMCN / Passport number",
    private_mail: 'Private email address',
    private_mail_hint: 'We will send you your new credentials here',
    student_mail: 'SingiMail address',
    student_mail_example: 'name.surname.23@singimail.rs',
    message: 'Message',
    agree: `I confirm that I have entered <span class="fw-bold">my personal data</span> and not from another student`,
    send: 'Send request',
    name_err: 'First name is required',
    lastname_err: 'Last name is required',
    index_err: 'Index number is required',
    jmbg_err: 'UMCN or Passport number is required',
    priv_email_err: 'Private email address is required',
    stud_email_err: 'Student mail address is required',
    priv_email_malformed: 'Private email address is malformed',
    stud_email_malformed: 'Singimail address is malformed',
    agree_err: 'You have to confirm that you have entered your own data',
    success_msg: 'Success! Please be patient while we process your request',
    error_msg: 'Something went wrong, please try again later',
    success_title: 'Success!',
    error_title: 'Error!',
    processing: 'Processing...',
    search: 'Search'
}